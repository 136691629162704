<template>
  <v-container class="d-block">
    <v-row no-gutters>
      <rents-calendar></rents-calendar>
    </v-row>
    <v-row no-gutters class="d-flex justify-space-between">
      <template v-for="item in statusesAndColors">
        <v-btn class="ma-2" :key="item.status" small :color="item.color"
          >{{ item.label }}
        </v-btn>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { statusesAndColors } from "@/store/utils/rentStatus";
import RentsCalendar from "@/components/existent-rents/RentsCalendar.vue";
export default {
  name: "ExistentRentsPage",
  components: {
    RentsCalendar,
  },
  data() {
    return {
      statusesAndColors: statusesAndColors,
    };
  },
  methods: {
    ...mapActions(["getMyRents", "getProducts"]),
  },
  mounted() {
    this.getMyRents(this.currentUser.userId);
    this.getProducts();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
