<template>
  <div id="footer">
    <v-row no-gutters class="d-flex justify-between">
      <div class="col-md-4">
        <div class="d-flex justify-center">
          <v-icon left>mdi-phone</v-icon>
          <h4>0756 654 107</h4>
        </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-center">
          <v-icon left>mdi-email</v-icon>
          <h4>docolina.petru.george@gmail.com</h4>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-center">
          <v-icon left>mdi-domain</v-icon>
          <h4>Docolina Petru George II</h4>
          </div>
        </div>
    </v-row>
  </div>
</template>

<script>
import "./FooterComponent.css";
export default {
  name: "FooterComponent",
};
</script>


