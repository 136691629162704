<template>
  <div>
    <h1>Informații</h1>
    <v-col class="d-flex justify-end flex-column text-subtitle1">
      În apropiere de Alba Iulia, oferim închirierea a trei ATV-uri de calitate
      superioară pentru cei care doresc să exploreze frumusețile naturale din
      zonă. Toate ATV-urile noastre sunt bine întreținute și echipate cu
      caracteristici moderne pentru a asigura o experiență sigură și
      confortabilă. Fie că doriți să explorați pădurile învecinate, să urcați
      dealurile învecinate sau să traversați râurile locale, aceste ATV-uri sunt
      perfect echipate pentru a face față oricăror provocări. Tarifele noastre
      sunt competitive și oferim opțiuni de rezervare flexibile, inclusiv
      pe termen scurt sau lung. Nu ezitați să ne contactați pentru a
      rezerva unul dintre ATV-urile noastre pentru următoarea dvs. aventură în
      Alba Iulia! Zona disponibilă pentru rezervare este afișată pe harta de
      mai jos.
    </v-col>
    <contact-form></contact-form>
    <div class="d-flex justify-center ma-5"><info-map></info-map></div>
  </div>
</template>

<script>
import ContactForm from "@/components/shared/ContactForm.vue";
import InfoMap from "@/components/info/InfoMap.vue";
export default {
  name: "InfoPage",
  components: {
    ContactForm,
    InfoMap,
  },
};
</script>
